import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import websitePage, { headerTypes } from 'hoc/websitePage/websitePage';
import withApplicationFormDrawer from 'hoc/withApplicationFormDrawer/withApplicationFormDrawer';
import { CREDIT_CARD } from 'utils/prospectTypes';

const SectionFormVariant = dynamic(import('./patterns/SectionFormVariant/SectionFormVariant'), {
  ssr: true,
});
const SectionBenefitsCardsVariant = dynamic(
  import('./patterns/SectionBenefitsCardsVariant/SectionBenefitsCards'),
  { ssr: true },
);
const SectionProducts = dynamic(import('./patterns/SectionProducts/SectionProducts'), {
  ssr: true,
});
const SectionMastercardVariant = dynamic(
  import('./patterns/SectionMastercardVariant/SectionMastercardVariant'),
  { ssr: true },
);
const SectionNoTaxesVariant = dynamic(
  import('./patterns/SectionNoTaxesVariant/SectionNoTaxesVariant'),
  { ssr: true },
);
const SectionWantedVariant = dynamic(
  import('./patterns/SectionWantedVariant/SectionWantedVariant'),
  { ssr: true },
);

export function CreditCard({ onFormVisibilityChange }) {
  const { formatMessage } = useContext(NuDsContext);

  // eslint-disable-next-line no-unused-vars
  const cardsArea = formatMessage({ id: 'cms.cardPage' });

  const CARD_MAP = [
    SectionMastercardVariant,
    SectionBenefitsCardsVariant,
    SectionNoTaxesVariant,
    SectionProducts,
    SectionWantedVariant,
  ];

  return (
    <>
      <SectionFormVariant onFormVisibilityChange={onFormVisibilityChange} />
      {/* {cardsArea.map((cardCard, index) => {
            const CardComponent = cardsMap[cardCard.componentName];

            if (!CardComponent) return null;

            return <CardComponent key={cardCard.id} index={index} />;
          })} */}

      {CARD_MAP.map(CardComponent => (
        <CardComponent />
      ))}
    </>
  );
}

CreditCard.propTypes = {
  onFormVisibilityChange: PropTypes.func,
};

CreditCard.defaultProps = {
  onFormVisibilityChange: () => { },
};

export default withApplicationFormDrawer(
  websitePage({
    routeKey: 'CARD',
    header: headerTypes.PRODUCT_PAGE,
  })(CreditCard),
  {
    prospectTypeKey: CREDIT_CARD,
    formTitle: 'SHORT_FORM.CREDIT_CARD.COMPLETE_FORM_TITLE',
    formVerticalTitle: 'SHORT_FORM.CREDIT_CARD.COMPLETE_FORM_VERTICAL_TITLE',
    withProductChoice: true,
  },
);
